import React, { useEffect, useState } from 'react';

const Dedupe = ({ lead_id }) => {
  const [dedupeData, setDedupeData] = useState([]);
  const [dedupeStrategy, setDedupeStrategy] = useState('exact');

  // Mock function for fetching dedupe data based on the strategy
  const fetchDedupeData = async (strategy) => {
    // Mock data for demonstration purposes
    let data;

    switch (strategy) {
      case 'exact':
        data = [
          { leadId: 66, loanNumber: 'LN001', customerName: 'John Doe', phoneNumber: '1234567890' },
          { leadId: 67, loanNumber: 'LN002', customerName: 'John Doe', phoneNumber: '1234567890' },
        ];
        break;

      case 'fuzzy':
        data = [
          { leadId: 68, loanNumber: 'LN003', customerName: 'Jon Doe', phoneNumber: '1234567891' },
          { leadId: 69, loanNumber: 'LN004', customerName: 'John Dough', phoneNumber: '1234567892' },
        ];
        break;

      case 'probabilistic':
        data = [
          { leadId: 70, loanNumber: 'LN005', customerName: 'J. Doe', phoneNumber: '1234567890' },
          { leadId: 71, loanNumber: 'LN006', customerName: 'John D.', phoneNumber: '1234567890' },
        ];
        break;

      default:
        data = [];
    }

    setDedupeData(data);
  };

  // Fetch dedupe data when the component loads or when the strategy changes
  useEffect(() => {
    fetchDedupeData(dedupeStrategy);
  }, [dedupeStrategy]);

  return (
    <>
      <div className="dedupe-header text-center" style={{ marginBottom: '20px' }}>
        <h3>Deduplication for Lead ID: {lead_id}</h3>
        <div className="dedupe-strategy-selector" style={{ marginTop: '15px' }}>
          <label>Select Deduplication Strategy: </label>
          <select
            value={dedupeStrategy}
            onChange={(e) => setDedupeStrategy(e.target.value)}
            style={{
              padding: '8px',
              marginLeft: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              fontSize: '14px',
            }}
          >
            <option value="exact">Exact Match</option>
            <option value="fuzzy">Fuzzy Matching</option>
            <option value="probabilistic">Probabilistic Matching</option>
            <option value="rules">Rules-Based Deduplication</option>
            <option value="key">Key-Based Deduplication</option>
          </select>
        </div>
      </div>

      <table className="table table-bordered table-hover" style={{ tableLayout: 'fixed', width: '100%', marginTop: '10px' }}>
        <thead style={{ backgroundColor: '#f7f7f7' }}>
          <tr>
            <th style={{ width: '20%', textAlign: 'center' }}>Lead ID</th>
            <th style={{ width: '20%', textAlign: 'center' }}>Loan Number</th>
            <th style={{ width: '30%', textAlign: 'center' }}>Customer Name</th>
            <th style={{ width: '30%', textAlign: 'center' }}>Phone Number</th>
          </tr>
        </thead>
        <tbody>
          {dedupeData.length > 0 ? (
            dedupeData.map((record, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{record.leadId}</td>
                <td style={{ textAlign: 'center' }}>
                  <a href={`/loan/${record.loanNumber}`} style={{ color: '#007bff', textDecoration: 'none' }}>
                    {record.loanNumber}
                  </a>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <a href={`/customer/${record.customerName}`} style={{ color: '#007bff', textDecoration: 'none' }}>
                    {record.customerName}
                  </a>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <a href={`tel:${record.phoneNumber}`} style={{ color: '#007bff', textDecoration: 'none' }}>
                    {record.phoneNumber}
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No duplicate records found for the selected strategy.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Dedupe;
