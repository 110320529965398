import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Label, Row, Col, CardFooter, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { GetCAMDetails, SaveCAMDetails, HeaderApi } from '../../api';
import { calculateDateDifference, roundToTwo, formatDateYYYYMMDD, formatDate } from '../../utils';
import { toast } from 'react-toastify';
import { fetchPersonalData, fetchEmployementData } from '../Task/Tasks';
import { useSelector } from 'react-redux';

const Update = "SAVE CAM";
const Edit = "EDIT CAM";
const cellStyle = {
  width: '25%'
};
const CAM = ({ lead_id }) => {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({});
  const [leaddata, setLeadData] = useState({});
  const [camData, setCAMData] = useState({});
  const [employmentData, setEmploymentData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const userID = localStorage.getItem('user_id');
  const [roleIds, setRoleIds] = useState(() => {
    const storedRoleIds = localStorage.getItem('role_ids');
    return storedRoleIds ? JSON.parse(storedRoleIds) : [];
  });
  const customerDetails = useSelector((state) => state.customerDetails);

  const convertFormData = (data) => ({
    ...data,
    lead_id: parseInt(lead_id, 10),
    repeat_times: parseInt(data.repeat_times, 10),
    eligible_tenure: parseInt(data.eligible_tenure, 10),
    eligible_foir_percentage: parseInt(data.eligible_foir_percentage, 10),
    eligible_loan: (parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100) ? (parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100) : "0",
    credit_bureau_score: parseInt(data.credit_bureau_score, 10),
    tenure: calculateDateDifference(formData.disbursal_date, formData.repayment_date) ? calculateDateDifference(formData.disbursal_date, formData.repayment_date) : "0",
    salary_amount_1: data.salary_amount_1 ? parseInt(data.salary_amount_1, 10) : "0",
    salary_amount_2: data.salary_amount_2 ? parseInt(data.salary_amount_2, 10) : "0",
    salary_amount_3: data.salary_amount_3 ? parseInt(data.salary_amount_3, 10) : "0",
    average_salary: ((parseInt(formData.salary_amount_1) + parseInt(formData.salary_amount_2) + parseInt(formData.salary_amount_3)) / 3) ? ((parseInt(formData.salary_amount_1) + parseInt(formData.salary_amount_2) + parseInt(formData.salary_amount_3)) / 3) : "0",
    actual_net_salary: data.actual_net_salary ? parseInt(data.actual_net_salary, 10) : "0",
    obligations: data.obligations ? parseInt(data.obligations, 10) : "0",
    eligible_roi: data.eligible_roi ? parseFloat(data.eligible_roi, 10) : "0",
    eligible_admin_fee: data.eligible_admin_fee ? parseInt(data.eligible_admin_fee, 10) : "0",
    disbursal_date: data.disbursal_date ? formatDateYYYYMMDD(data.disbursal_date) : new Date(),
    final_foir_percentage: ((parseInt(formData.loan_recommended) / parseInt(formData.actual_net_salary)) * 100) ? ((parseInt(formData.loan_recommended) / parseInt(formData.actual_net_salary)) * 100) : "0",
    admin_fee: formData.admin_fee,
    panel_roi: parseFloat(formData.roi) + parseFloat(formData.roi),
    admin_fee_gst: "18",
    dedupe_check: data.dedupe_check ? parseInt(data.dedupe_check, 10) : 0,
    admin_fee_amount: (parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) ? (parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) : "0",
    admin_fee_gst_amount: ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * parseInt(formData.admin_fee_gst) / 100) ? ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * parseInt(formData.admin_fee_gst) / 100) : "0",
    total_admin_fee_amount: (parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18 / 100) ? (parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18 / 100) : "0",
    net_disbursal_amount: parseInt(formData.loan_recommended) - ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18 / 100)) ? parseInt(formData.loan_recommended) - ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18 / 100)) : "0",
    repayment_amount: (parseInt(formData.loan_recommended) + ((parseInt(formData.loan_recommended) * parseFloat(formData.roi) * calculateDateDifference(formData.disbursal_date, formData.repayment_date)) / 100)) ? (parseInt(formData.loan_recommended) + ((parseInt(formData.loan_recommended) * parseFloat(formData.roi) * calculateDateDifference(formData.disbursal_date, formData.repayment_date)) / 100)) : "0",
  });

  const fetchData = useCallback(async () => {
    try {
      // const personalData = await fetchPersonalData(lead_id);
      const personalData = customerDetails;
      setData(() => {
        try {
          return personalData.data || {};
        } catch (error) {
          console.error('Error parsing personal_data from localStorage:', error);
          return {};
        }
      });

      setLeadData(() => {
        try {
          return personalData.data.lead || {};
        } catch (error) {
          console.error('Error parsing personal_data from localStorage:', error);
          return {};
        }
      });

    } catch (error) {
      console.error('Error with the Axios request:', error);
      setData({});
    }
  }, [lead_id]);

  const fetchCAMData = useCallback(async () => {
    let apiUrl = `${GetCAMDetails}${lead_id}`;
    try {
      const response = await axios.get(apiUrl, { headers: HeaderApi });
      setCAMData(response.data.data);
      localStorage.setItem('cam_data', JSON.stringify(response.data.data));
    } catch (error) {
      console.error('Error with the Axios request:', error);
      setData({});
    }
  }, [lead_id]);

  const fetchEmploymentData = useCallback(async () => {
    try {
      const response = await fetchEmployementData(lead_id);
      setEmploymentData(response.data);

      localStorage.setItem('customer_designation', response.data.data?.emp_designation.name);
    } catch (error) {
      console.error('Error with the Axios request:', error);
      setData({});
    }
  }, [lead_id]);

  useEffect(() => {
    fetchEmploymentData();
    fetchCAMData();
    fetchData();
  }, [fetchData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.target.value = value.toUpperCase();

    setFormData((formData) => ({
      ...formData,
      [name]: value.toUpperCase()
    }));

    if (name === "remarks") {
      setFormData((formData) => ({
        ...formData,
        remarks: value.toUpperCase()
      }));
    }

    if (name === "actual_net_salary") {
      const { categoryWeightage, customerCategory } = calculateCategory(value);
      console.log(categoryWeightage, customerCategory);
      setFormData((formData) => ({
        ...formData,
        eligible_foir_percentage: categoryWeightage,
        customer_category: customerCategory,
      }));
    }

    setFormErrors((errors) => ({
      ...errors,
      [name]: validateField(name, value)
    }));
  };

  const calculateCategory = (updatedSalary) => {
    let categoryWeightage = 0;
    let customerCategory = "";

    switch (true) {
      case leaddata.user_type === "REPEAT":
        categoryWeightage = 50;
        customerCategory = "CAT X";
        break;
      case leaddata.alternate_email !== "" && (data.residence_type_id === 1 || data.residence_type_id === 3) && updatedSalary >= 25000 && updatedSalary < 35000:
        categoryWeightage = 40;
        customerCategory = "CAT A";
        break;
      case leaddata.alternate_email !== "" && (data.residence_type_id === 1 || data.residence_type_id === 3) && updatedSalary >= 35000 && updatedSalary < 50000:
        categoryWeightage = 45;
        customerCategory = "CAT A";
        break;
      case leaddata.alternate_email !== "" && (data.residence_type_id === 1 || data.residence_type_id === 3) && updatedSalary >= 50000:
        categoryWeightage = 50;
        customerCategory = "CAT A";
        break;
      case (leaddata.alternate_email !== "" || (data.residence_type_id === 1 || data.residence_type_id === 3)) && updatedSalary >= 25000 && updatedSalary < 35000:
        categoryWeightage = 35;
        customerCategory = "CAT B";
        break;
      case (leaddata.alternate_email !== "" || (data.residence_type_id === 1 || data.residence_type_id === 3)) && updatedSalary >= 35000 && updatedSalary < 50000:
        categoryWeightage = 40;
        customerCategory = "CAT B";
        break;
      case (leaddata.alternate_email !== "" || (data.residence_type_id === 1 || data.residence_type_id === 3)) && updatedSalary >= 50000:
        categoryWeightage = 45;
        customerCategory = "CAT B";
        break;
      case leaddata.alternate_email === "" && data.residence_type_id === 2 && updatedSalary >= 25000 && updatedSalary < 35000:
        categoryWeightage = 30;
        customerCategory = "CAT C";
        break;
      case leaddata.alternate_email === "" && data.residence_type_id === 2 && updatedSalary >= 35000 && updatedSalary < 50000:
        categoryWeightage = 35;
        customerCategory = "CAT C";
        break;
      case leaddata.alternate_email === "" && data.residence_type_id === 2 && updatedSalary >= 50000:
        categoryWeightage = 40;
        customerCategory = "CAT C";
        break;
      default:
        break;
    }


    return { categoryWeightage, customerCategory };

  };

  useEffect(() => {

    setFormData({
      salary_date_1: camData.salary_credit1_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.salary_credit1_date) : "",
      salary_amount_1: camData.salary_credit1_amount ? camData.salary_credit1_amount : "",
      salary_date_2: camData.salary_credit2_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.salary_credit2_date) : "",
      salary_amount_2: camData.salary_credit2_amount ? camData.salary_credit2_amount : "",
      salary_date_3: camData.salary_credit3_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.salary_credit3_date) : "",
      salary_amount_3: camData.salary_credit3_amount ? camData.salary_credit3_amount : "",
      next_pay_date: camData.next_pay_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.next_pay_date) : "",
      average_salary: camData.median_salary_amount ? camData.median_salary_amount : "", // Automatically Filled
      actual_net_salary: camData.actual_net_salary ? camData.actual_net_salary : "", // Automatically Filled
      credit_bureau_score: leaddata.bureau_score ? leaddata.bureau_score : "", // Automatically Filled
      customer_type: leaddata.user_type ? leaddata.user_type : "", // Automatically Filled
      dedupe_check: camData.dedupe_check ? camData.dedupe_check : "", // Automatically Filled
      customer_category: camData?.customer_category || "", // Automatically Filled
      repeat_times: leaddata.repeat_times ? leaddata.repeat_times : "0", // Automatically Filled
      obligations: leaddata.obligations ? leaddata.obligations : "", // Automatically Filled
      eligible_foir_percentage: camData?.eligible_foir_percentage || "", // Automatically Filled
      eligible_loan: formData.eligible_loan ? formData.eligible_loan : "", // Automatically Filled
      eligible_roi: "1", // Automatically Filled
      eligible_admin_fee: "10", // Automatically Filled
      eligible_tenure: "30",  // Automatically Filled
      disbursal_date: camData.disbursal_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.disbursal_date) : formatDateYYYYMMDD(new Date()),
      final_foir_percentage: camData.final_foir_percentage ? camData.final_foir_percentage : "0", // Automatically Filled
      loan_recommended: camData.loan_recommended ? camData.loan_recommended : "", // Automatically Filled
      roi: camData.roi ? camData.roi : "0", // Automatically Filled
      admin_fee: camData.admin_fee ? camData.admin_fee : "10", // Automatically Filled
      admin_fee_amount: camData.admin_fee_amount ? camData.admin_fee_amount : "", // Automatically Filled
      admin_fee_gst: "18", // Automatically Filled
      admin_fee_gst_amount: camData.admin_fee_gst ? camData.admin_fee_gst : "", // Automatically Filled
      total_admin_fee: camData.total_admin_fee ? camData.total_admin_fee : "", // Automatically Filled
      net_disbursal_amount: camData.net_disbursal_amount ? camData.net_disbursal_amount : "", // Automatically Filled
      tenure: camData.tenure ? camData.tenure : "", // Automatically Filled
      repayment_date: camData.repayment_date != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(camData.repayment_date) : "",
      repayment_amount: camData.repayment_amount ? camData.repayment_amount : "",
      remarks: camData.remarks ? camData.remarks : "",
    });
  }, [data, employmentData, leaddata, camData]);

  const onEditSubmit = async (e) => {
    e.preventDefault();
    const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
    setFormErrors(errors);
    if (!Object.values(errors).some(error => error)) {
      var convertedData = convertFormData(formData);
      console.log(convertedData);
      let apiUrl = `${SaveCAMDetails}`;
      try {
        const response = await axios.post(apiUrl, convertedData, { headers: HeaderApi });
        toast.success("CAM Details Updated Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        fetchEmploymentData();
        fetchData();
        fetchCAMData();
      } catch (error) {
        toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
      setEditMode(false);
    }
  };

  const validateField = (name, value) => {
    const valueString = value.toString().trim();
    let eligible_amount = (parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100) ? roundToTwo((parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100)) : "";
    switch (name) {
      case 'salary_date_1': return valueString.trim() ? '' : 'Salary Date is required';
      case 'salary_date_2': return valueString.trim() ? '' : 'Salary Date is required';
      case 'salary_date_3': return valueString.trim() ? '' : 'Salary Date is required';
      case 'next_pay_date': return valueString.trim() ? '' : 'Next Pay Date is required';
      case 'salary_amount_1': return valueString.trim() > 0 ? '' : 'Salary Amount is required';
      case 'salary_amount_2': return valueString.trim() > 0 ? '' : 'Salary Amount is required';
      case 'salary_amount_3': return valueString.trim() > 0 ? '' : 'Salary Amount is required';
      case 'average_salary': return valueString.trim() ? '' : 'Salary Amount is required';
      case 'actual_net_salary': return valueString.trim() > 0 ? '' : 'Net Salary Amount is required';
      case 'dedupe_check': return valueString.trim() ? '' : 'Dedupe is required';
      case 'obligations': return valueString.trim() ? '' : 'Obligations is required';
      case 'disbursal_date': return valueString.trim() ? '' : 'Disbursal Date is required';
      case 'loan_recommended': return valueString.trim() > 0
        ? (eligible_amount < parseInt(valueString.trim())
          ? 'Cannot be greater than eligible amount.'
          : '')
        : 'Loan amount is required';
      case 'roi': return valueString.trim() > 0 ? '' : 'Roi not in digits';
      case 'repayment_date': return valueString.trim() ? '' : 'Repayment Date is required';
      case 'remarks': return valueString.trim() ? '' : 'Name is required';
      default: return '';
    }
  };

  const renderInput = useCallback((key, value) => {
    switch (key) {
      case "salary_date_1":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "salary_date_2":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "salary_date_3":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "next_pay_date":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "average_salary":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={(parseInt(formData.salary_amount_1) + parseInt(formData.salary_amount_2) + parseInt(formData.salary_amount_3) / 3) ? roundToTwo((parseInt(formData.salary_amount_1) + parseInt(formData.salary_amount_2) + parseInt(formData.salary_amount_3)) / 3) : ""
            }
            disabled={true}
          />
        );
      case "actual_net_salary":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            onChange={handleInputChange}
          />
        );
      case "credit_bureau_score":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            disabled={true}
          />
        );
      case "customer_type":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            disabled={true}
          />
        );
      case "customer_category":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            disabled={true}
          />
        );
      case "repeat_times":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            disabled={true}
          />
        );
      case "eligible_loan":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={(parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100) ? roundToTwo((parseInt(formData.actual_net_salary) * (parseInt(formData.eligible_foir_percentage)) / 100)) : ""}
            disabled={true}
          />
        );
      case "eligible_foir_percentage":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value + "%"}
            disabled={true}
          />
        );
      case "eligible_roi":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value + "%"}
            disabled={true}
          />
        );
      case "eligible_tenure":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            disabled={true}
          />
        );
      case "final_foir_percentage":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={((parseInt(formData.loan_recommended) / parseInt(formData.actual_net_salary)) * 100) ? roundToTwo(((parseInt(formData.loan_recommended) / parseInt(formData.actual_net_salary)) * 100)) + "%" : ""}
            disabled={true}
          />
        );
      case "admin_fee":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            // disabled={true}
            onChange={handleInputChange}
          />
        );
      case "admin_fee_amount":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={formData.loan_recommended ? (parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) : ""}
            disabled={true}
          />
        );
      case "admin_fee_gst":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value + "%"}
            disabled={true}
          />
        );
      case "admin_fee_gst_amount":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={formData.loan_recommended ? roundToTwo(((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * parseInt(formData.admin_fee_gst) / 100)) : ""}
            disabled={true}
          />
        );
      case "total_admin_fee":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={(formData.loan_recommended ? roundToTwo((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18) / 100) : "")}
            disabled={true}
          />
        );
      case "net_disbursal_amount":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={formData.loan_recommended ? roundToTwo((parseInt(formData.loan_recommended) - ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) + ((parseInt(formData.admin_fee) * parseInt(formData.loan_recommended) / 100) * 18) / 100))) : ""}
            disabled={true}
          />
        );
      case "eligible_admin_fee":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value + "%"}
            disabled={true}
          />
        );
      case "tenure":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={calculateDateDifference(formData.disbursal_date, formData.repayment_date) ? calculateDateDifference(formData.disbursal_date, formData.repayment_date) : ""}
            disabled={true}
          />
        );
      case "disbursal_date":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={value ? formatDateYYYYMMDD(value) : formatDateYYYYMMDD(new Date())}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "repayment_date":
        return (
          <Input
            id={key}
            type="date"
            name={key}
            value={formatDateYYYYMMDD(value)}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );
      case "repayment_amount":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={(parseInt(formData.loan_recommended) + roundToTwo(((parseInt(formData.loan_recommended) * parseFloat(formData.roi) * calculateDateDifference(formData.disbursal_date, formData.repayment_date)) / 100))) ? (parseInt(formData.loan_recommended) + roundToTwo(((parseInt(formData.loan_recommended) * parseFloat(formData.roi) * calculateDateDifference(formData.disbursal_date, formData.repayment_date)) / 100))) : ""}
            disabled={true}
          />
        );
      case "remarks":
        return (
          <textarea
            id={key}
            name={key}
            value={formData.remarks}
            className={`form-control ${formErrors['remarks'] ? 'is-invalid' : ''}`}
            onChange={handleInputChange}
            rows="2"
          ></textarea>

        );
      case "dedupe_check":
        return (
          <Input id="dedupe_check" name="dedupe_check" type="select" value={formData.dedupe_check} invalid={!!formErrors['dedupe_check']} onChange={handleInputChange}>
            <option value="">Select Dedupe Check</option>
            <option value="1">YES</option>
            <option value="2">NO</option>
          </Input>
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            defaultValue={value}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange]);

  const renderFormGroups = Object.entries(formData).reduce((acc, entry, index, array) => {
    if (index % 2 === 0) {
      acc.push(array.slice(index, index + 2));
    }
    return acc;
  }, []).map((pair, index) => (
    <FormGroup row key={index}>
      {pair.map(([key, value]) => (
        <Fragment key={key}>
          <Label for={key} sm={2}>
            {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
          </Label>
          <Col sm={4}>
            {renderInput(key, value)}
            {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
          </Col>
        </Fragment>
      ))}
    </FormGroup>
  ));

  return (
    <Fragment>
      <div className="edit-profile" style={{ marginTop: 10 }}>
        <Row>
          <Col xl="12">
            <Card>
              <Form onSubmit={onEditSubmit}>
                <CardBody>
                  {editMode ? (
                    <>
                      {renderFormGroups.length > 0 ? renderFormGroups : null}
                    </>
                  ) : (
                    <Fragment>
                      <Row>
                        <table className="table table-bordered table-hover table-striped" style={{ marginTop: "10px" }}>
                          <tbody>
                            <tr>
                              <th className="text-nowrap" style={cellStyle} scope="row">Lead Id</th>
                              <td style={cellStyle}>{leaddata.ID ? leaddata.ID : "-"}</td>
                              <th style={cellStyle} className="text-nowrap" scope="row">Loan No</th>
                              <td style={cellStyle}>{leaddata.loan_no ? leaddata.loan_no : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Salary Date 1</th>
                              <td>{camData.salary_credit1_date != "0001-01-01T00:00:00Z" ? formatDate(camData.salary_credit1_date) : "-"}</td>
                              <th className="text-nowrap" scope="row">Salary Amount 1</th>
                              <td>{camData.salary_credit1_amount ? camData.salary_credit1_amount : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Salary Date 2</th>
                              <td>{camData.salary_credit2_date != "0001-01-01T00:00:00Z" ? formatDate(camData.salary_credit2_date) : "-"}</td>
                              <th className="text-nowrap" scope="row">Salary Amount 2</th>
                              <td>{camData.salary_credit2_amount ? camData.salary_credit2_amount : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Salary Date 3</th>
                              <td>{camData.salary_credit3_date != "0001-01-01T00:00:00Z" ? formatDate(camData.salary_credit3_date) : "-"}</td>
                              <th className="text-nowrap" scope="row">Salary Amount 3</th>
                              <td>{camData.salary_credit3_amount ? camData.salary_credit3_amount : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Next Salary Date</th>
                              <td>{camData.next_pay_date != "0001-01-01T00:00:00Z" ? formatDate(camData.next_pay_date) : "-"}</td>
                              <th className="text-nowrap" scope="row">Median Salary Amount</th>
                              <td>{camData.median_salary_amount ? camData.median_salary_amount : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Average Salary</th>
                              <td>{camData.salary_credit1_amount ? roundToTwo((parseInt(camData.salary_credit1_amount) + parseInt(camData.salary_credit2_amount) + parseInt(camData.salary_credit3_amount)) / 3, 2) : "-"}</td>
                              <th className="text-nowrap" scope="row">Actual Net Salary</th>
                              <td>{camData.actual_net_salary ? camData.actual_net_salary : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Credit Bureau Score</th>
                              <td>{leaddata.bureau_score ? leaddata.bureau_score : "-"}</td>
                              <th className="text-nowrap" scope="row">Customer Type</th>
                              <td>{leaddata.user_type ? leaddata.user_type : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Dedupe check</th>
                              <td>{camData.dedup_check === 1 ? "YES" : "NO"}</td>
                              <th className="text-nowrap" scope="row">Customer Category</th>
                              <td>{camData.customer_category ? camData.customer_category : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Repeat Times</th>
                              <td>{0}</td>
                              <th className="text-nowrap" scope="row">Obligations(Rs)</th>
                              <td>{leaddata.obligations ? leaddata.obligations : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Eligible FOIR</th>
                              <td>{camData.eligible_foir_percentage ? camData.eligible_foir_percentage : "-"}</td>
                              <th className="text-nowrap" scope="row">Eligible Loan Amt</th>
                              <td>{camData.eligible_loan ? camData.eligible_loan : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Net Disbursal</th>
                              <td>{camData.net_disbursal_amount ? camData.net_disbursal_amount : "-"}</td>
                              <th className="text-nowrap" scope="row">Tenure</th>
                              <td>{camData.tenure ? camData.tenure : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Disbursal Date</th>
                              <td>{camData.disbursal_date != "0001-01-01T00:00:00Z" ? formatDate(camData.disbursal_date) : "-"}</td>
                              <th className="text-nowrap" scope="row">Repayment Date</th>
                              <td>{camData.repayment_date != "0001-01-01T00:00:00Z" ? formatDate(camData.repayment_date) : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">FOIR</th>
                              <td>{camData.final_foir_percentage ? camData.final_foir_percentage + "%" : "-"}</td>
                              <th className="text-nowrap" scope="row">Loan Amount</th>
                              <td>{camData.loan_recommended ? camData.loan_recommended : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">ROI</th>
                              <td>{camData.roi ? camData.roi + "%" : "-"}</td>
                              <th className="text-nowrap" scope="row">Admin fee (in%)</th>
                              <td>{camData.admin_fee ? camData.admin_fee + "%" : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Net Admin fee Amt</th>
                              <td>{camData.admin_fee_amount ? camData.admin_fee_amount : "-"}</td>
                              <th className="text-nowrap" scope="row">GST (%)</th>
                              <td>{camData.admin_fee_gst ? camData.admin_fee_gst + "%" : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Total Admin fee amt</th>
                              <td>{camData.total_admin_fee_amount ? camData.total_admin_fee_amount : "-"}</td>
                              <th className="text-nowrap" scope="row">Panel Roi</th>
                              <td>{camData.panel_roi ? camData.panel_roi : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Remarks</th>
                              <td colSpan="3">{camData.remarks ? camData.remarks : "-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Row>
                    </Fragment>
                  )}
                </CardBody>
                {editMode && (
                  <CardFooter className="text-end">
                    <Button color="btn btn-outline-success" type="submit">{editMode ? Update : Edit}</Button>
                    <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setEditMode(!editMode)}>CANCEL</Button>
                  </CardFooter>
                )}
              </Form>
              {!editMode && (
                <>
                  {(leaddata.application_status == 1 && leaddata.stp_flag == 0 && roleIds.includes(4) && leaddata.credit_assign_user_id == userID && leaddata.lead_status_id < 14 && leaddata.stage !== "S7") && (
                    <CardFooter className="text-center">
                      <Button color="btn btn-outline-info" onClick={() => setEditMode(!editMode)}>{editMode ? Update : Edit}</Button>
                    </CardFooter>
                  )}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CAM;
