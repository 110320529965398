import React, { useState, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { Application, Documents, Personal, Banking, Verification, CAM, Disbursal, Repayment, ApplicationDataLogs, Dedupe } from '../Tabs/index';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { lead_status_id, id } = useParams();
  const location = useLocation();

  // Use useMemo to create the tab content map
  const tabContentMap = useMemo(() => ({
    '1': <Personal lead_id={id} />,
    '2': <Application lead_id={id} />,
    '3': <Documents lead_id={id} />,
    '4': lead_status_id >= 7 ? <Banking lead_id={id} /> : null,
    '5': lead_status_id >= 7 ? <Verification lead_id={id} /> : null,
    '6': lead_status_id >= 7 ? <CAM lead_id={id} /> : null,
    '7': lead_status_id > 15 ? <Disbursal lead_id={id} /> : null,
    '8': lead_status_id > 20 ? <Repayment lead_id={id} /> : null,
    '9': <ApplicationDataLogs lead_id={id} />,
    '10': lead_status_id >= 7 ? <Dedupe lead_id={id} /> : null,
  }), [id, lead_status_id]);

  // Update activeTab based on URL hash when component mounts or URL changes
  React.useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash && ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].includes(hash)) {
      setActiveTab(hash);
    }
  }, [location.hash]);

  const toggleTab = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const renderNavItem = useCallback((tabId, icon, text) => (
    <NavItem key={tabId}>
      <NavLink
        href={`#${tabId}`}
        className={activeTab === tabId ? 'active' : ''}
        onClick={(e) => {
          e.preventDefault();
          toggleTab(tabId);
        }}
      >
        <i className={`icofont icofont-${icon}`}></i>{text}
      </NavLink>
    </NavItem>
  ), [activeTab, toggleTab]);

  return (
    <Col sm="12">
      <Card>
        <CardBody>
          <Nav tabs>
            {renderNavItem('1', 'man-in-glasses', 'Personal')}
            {renderNavItem('2', 'ui-home', 'Application')}
            {renderNavItem('3', 'document-folder', 'Documents')}
            {lead_status_id >= 7 && (
              <>
                {renderNavItem('4', 'bank-alt', 'Banking')}
                {renderNavItem('5', 'verification-check', 'Verification')}
                {renderNavItem('10', 'data', 'Dedupe')}
                {renderNavItem('6', 'man-in-glasses', 'CAM')}
                {lead_status_id > 15 && (
                  <>
                    {renderNavItem('7', 'cur-rupee', 'Disbursal')}
                  </>
                )}
                {lead_status_id > 20 && (
                  <>
                    {renderNavItem('8', 'cur-rupee', 'Repayment')}
                  </>
                )}
              </>
            )}
            {renderNavItem('9', 'history', 'Logs')}
          </Nav>
          <div className="mt-4">
            {tabContentMap[activeTab]}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default React.memo(Tabs);
